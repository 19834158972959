import { useEffect, useState } from "react";
import Spinner from "../ui/spinner";

const Feature4Component = () => {
  const [isLoading, setIsLoading] = useState(true);

  const fetchData = () => {
    setIsLoading(false);
  };

  useEffect(fetchData, []);

  if (isLoading) {
    return <Spinner />;
  }
  return (
    <div className="overflow-hidden bg-white py-12">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2">
          <div className="lg:ml-auto lg:pl-4 lg:pt-4">
            <div className="lg:max-w-lg">
              <h2 className="text-base font-semibold leading-7 gradient-text">
                AI Employees
              </h2>
              <p className="mt-6 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                AI Collections
              </p>
              <p className="mt-2 text-lg leading-8 text-gray-600">Manages payment collections through email, text, and phone. Automatically follows up on unpaid bills, ensuring you get paid on time and keeping your cash flow steady.</p>
              <p className="mt-6 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                AI Data Analyst
              </p>
              <p className="mt-2 text-lg leading-8 text-gray-600">Monitors real-time metrics from AI Employees and internal systems, providing actionable insights and recommendations directly to your team. Tracks performance, identifies trends, and helps you make data-driven decisions to optimize your wholesale operations.</p>
            </div>
          </div>
          {/* hide in mobile */}
          <div className="flex items-start justify-end lg:order-first hidden sm:block">
            <img
              alt="Product screenshot"
              src="https://temporal-seels.s3.eu-west-2.amazonaws.com/landing/Section-4.png"
              className="w-[30rem] max-w-none rounded-xl sm:w-[35rem]"
            />
          </div>
          {/* hide in desktop */}
          <div className="flex block sm:hidden">
            <img
              alt="Product screenshot"
              src="https://temporal-seels.s3.eu-west-2.amazonaws.com/landing/Section-4.png"
              className="w-[20rem] rounded-xl"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Feature4Component;
